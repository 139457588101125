// routes
import {PATH_DASHBOARD} from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import WeekendRoundedIcon from '@mui/icons-material/WeekendRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import CalendarToday from '@mui/icons-material/CalendarToday';
import TableChartIcon from '@mui/icons-material/TableChart';
import ActivityIcon from '@mui/icons-material/ManageSearch';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{width: '100%', height: '100%'}}/>
);

// const ICONS = {
//   blog: getIcon('ic_blog'),
//   cart: getIcon('ic_cart'),
//   chat: getIcon('ic_chat'),
//   mail: getIcon('ic_mail'),
//   user: getIcon('ic_user'),
//   kanban: getIcon('ic_kanban'),
//   banking: getIcon('ic_banking'),
//   calendar: getIcon('ic_calendar'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
//   booking: getIcon('ic_booking')
// };

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: getIcon('ic_dashboard'),
        forAdmin: true
      },
      {
        title: 'Productos',
        path: PATH_DASHBOARD.store.products,
        icon: <WeekendRoundedIcon/>
      },
      {
        title: 'Cotizaciones',
        path: PATH_DASHBOARD.sales.quotes,
        icon: <ShoppingCartRoundedIcon/>
      },
    ]
  },
  {
    subheader: 'Producción',
    items: [

      {
        title: 'Órdenes',
        path: PATH_DASHBOARD.sales.orders,
        icon: <ArticleRoundedIcon/>,
      },
      {
        title: 'Calendario',
        path: PATH_DASHBOARD.sales.calendar,
        icon: <CalendarToday/>
      },
      {
        title: 'Kanban',
        path: PATH_DASHBOARD.kanban,
        icon: <TableChartIcon/>,
        forAdmin: true,
        forStaff: true
      },
    ]
  },
  {
    subheader: 'Administración',
    forAdmin: true,
    forStaff: true,
    items: [
      {
        title: 'Actividad',
        path: PATH_DASHBOARD.activity,
        icon: <ActivityIcon/>,
        forAdmin: true,
        forStaff: true
      },
      // { title: 'Administración', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking }
    ]
  },
];

export default sidebarConfig;
